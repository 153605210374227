import React from "react"
import MainHeader from "../main_header/main_header"
import MainFooter from "../footer/main_footer"
import loadable from "@loadable/component"
const ChatBox = loadable(() => import("./chat_box"), {
  fallback: <div></div>,
})
const Banner = loadable(() => import("./banner"), {
  fallback: <div></div>,
})

export default function ContactUs() {
  return (
    <React.Fragment>
      <MainHeader />
      <ChatBox />
      <Banner />
      <MainFooter />
    </React.Fragment>
  )
}
